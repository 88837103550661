import "./App.css";
import MuxVideo from "@mux/mux-video-react";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

function App() {
  const [count, setCount] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCount(true);
    }, 2000);
  }, []);

  return (
    <>
      <Modal
        size="md"
        show={count}
        onHide={() => setCount(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <p>Simple MuxVideo Example</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MuxVideo
            style={{ height: "1005", maxWidth: "100%" }}
            playbackId="54YT00zk7Jj4LoG29jROxyAI1C01TJkTEsqVL4xdx4cTo"
            metadata={{
              video_id: "video-id-123456",
              video_title: "Placeholder (optional)",
              viewer_user_id: "user-id-bc-789",
            }}
            primary-color="#FFFFFF"
            secondary-color="#000000"
            streamType="on-demand"
            controls
            autoPlay
            muted
          />
        </Modal.Body>
      </Modal>
      <div className="muxdemo">
            <h1>Welcome</h1>
      </div>      

    </>
  );
}

export default App;
